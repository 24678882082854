import { Flex, Link } from '@chakra-ui/react'
import Impressum from './Impressum'
import { RiLinkedinLine, RiXingLine } from 'react-icons/ri'

const Kontakt = () => {
  return (
    <>
      <Link
        href="mailto:mail@antje-mohr.com"
        m="auto"
        fontFamily="quattrocento"
        fontWeight="bold"
        fontSize={20}
        textAlign="center"
      >
        mail@antje-mohr.com
      </Link>
      <Flex gap={2} alignItems="center" flexWrap="wrap">
        <Link
          href="https://de.linkedin.com/in/antje-mohr"
          target="_linkedin"
          _hover={{ color: 'am.tuerkis.lighter' }}
        >
          <RiLinkedinLine />
        </Link>
        |
        <Link
          href="https://www.xing.com/profile/Antje_Mohr9"
          target="_xing"
          _hover={{ color: 'am.tuerkis.lighter' }}
        >
          <RiXingLine />
        </Link>
        |
        <Impressum />
      </Flex>
    </>
  )
}

export default Kontakt
