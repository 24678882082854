import { Text } from '@chakra-ui/react'

type AntjeMohrProps = Record<string, string | number | string[] | number[]>
const AntjeMohr = (props: AntjeMohrProps) => {
  return (
    <Text color="am.gray" fontFamily="century" {...props}>
      antje
      <Text as="span" color="am.tuerkis.darker">
        mohr
      </Text>
    </Text>
  )
}
export default AntjeMohr
