import { Box, Button, Flex, Heading, Image, Link, Text } from '@chakra-ui/react'
import Aquarell from './Aquarell'
import Kontakt from './Kontakt'
import { ImArrowUp2, ImHeart } from 'react-icons/im'
import ChapterHeading from './ChapterHeading'
import AntjeMohr from './AntjeMohr'

function App() {
  const scrollTo = (e: React.MouseEvent, targetSelector: string) => {
    e.preventDefault()
    document.querySelector(targetSelector)?.scrollIntoView()
  }

  return (
    <>
      <Image
        src="/assets/am_farbe.jpg"
        objectFit="cover"
        position="fixed"
        bottom={0}
        minW="100%"
        height="100vh"
      />

      <Flex position="relative" id="top" flexDirection="column" flex={1} minH="100vh" m="auto">
        <Flex>
          <Image src="/assets/am_header.jpg" />
          <Flex
            pos="absolute"
            width="100%"
            py={[2, 4, 6]}
            px={[4, 10, 20]}
            alignItems={['flex-start', 'flex-start', 'center']}
            flexWrap="wrap"
            justifyContent={['flex-start', 'flex-start', 'center']}
            flexDirection={['column', 'column', 'row']}
          >
            <AntjeMohr fontSize={[34, 42, 48]} />
            <Text
              display="flex"
              flex={1}
              justifyContent={['start', 'start', 'end']}
              gap={2}
              color="am.gray"
            >
              <Button variant="link" onClick={e => scrollTo(e, '#kontakt')}>
                Kontakt
              </Button>
              |
              <Link href="https://de.linkedin.com/in/antje-mohr" target="_linkedin">
                LinkedIn
              </Link>
              |
              <Link href="https://www.xing.com/profile/Antje_Mohr9" target="_xing">
                Xing
              </Link>
            </Text>
          </Flex>
        </Flex>

        <Flex flexDirection="column" py={[12, 20, 24]} px={4} bg="white">
          <Heading
            mb={5}
            color="am.orange.dark"
            fontFamily="oswald"
            fontSize={[50, 60, 70]}
            fontWeight="medium"
            letterSpacing={2}
            textAlign="center"
          >
            WILLKOMMEN
          </Heading>
          <Text m="auto" fontFamily="quattrocento" fontSize={[16, 18, 20]} textAlign="center">
            Hier findet Ihr eine kleine Auswahl meiner grafischen Experimente.
          </Text>
        </Flex>

        <Flex bg="am.orange.light" flexDirection="column" py={[12, 20, 24]} px={4}>
          <ChapterHeading
            head="AQUARELL KARTEN"
            suffix="diverse Formate"
            color="am.tuerkis.darker"
          />
          <Aquarell />
        </Flex>

        <Flex py={[12, 20, 24]} px={4} flexDirection="column" bg="white">
          <ChapterHeading head="ACRYL AUF LEINWAND" suffix="80 x 80 cm" color="am.orange.dark" />
          <Flex columnGap={[5, 25, 10]} flexWrap="wrap">
            <Image src="/leinwand/toskana.png" w={['80%', '33%', '25%']} />
            <Image src="/leinwand/face.png" w={['80%', '33%', '25%']} />
            <Image src="/leinwand/wood.png" w={['80%', '33%', '25%']} />
          </Flex>
        </Flex>

        <Box w="100%" h="150px" />

        <Flex
          id="kontakt"
          py={20}
          px={4}
          flexDirection="column"
          gap={4}
          color="am.tuerkis.darker"
          bg="white"
        >
          <Kontakt />
        </Flex>

        <Flex
          bg="am.tuerkis.light"
          py={4}
          px={4}
          w="100%"
          color="am.tuerkis.darker"
          alignItems="center"
          flexWrap="wrap"
          textAlign="center"
          fontSize={[12, 14, 16]}
        >
          &copy; 2024 | design with{' '}
          <Text as="span" p={2} color="am.tuerkis.lighter">
            <ImHeart />{' '}
          </Text>
          by <AntjeMohr ml={1} fontSize={[14, 16, 18]} />
        </Flex>
        <Button
          size="md"
          variant="ghost"
          position="fixed"
          left="auto"
          right={2}
          top="auto"
          bottom={2}
          _hover={{ bg: 'am.tuerkis.lighter' }}
          color="am.tuerkis.darker"
          onClick={e => scrollTo(e, '#top')}
        >
          <ImArrowUp2 />
        </Button>
      </Flex>
    </>
  )
}

export default App
