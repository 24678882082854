import { Text } from '@chakra-ui/react'

type ChapterHeadingProps = {
  head: string
  suffix: string
  color: string
}
const ChapterHeading = ({ head, suffix, color }: ChapterHeadingProps) => {
  return (
    <Text color={color} mb={12} display="flex" alignItems="end">
      <Text
        as="span"
        fontSize={[22, 26, 30]}
        fontFamily="oswald"
        fontWeight="medium"
        letterSpacing={2}
        textAlign="right"
      >
        {head}
      </Text>
      <Text as="span" fontSize={[26, 28, 30]} mb={[0, 0, '1px']} ml={2}>
        |
      </Text>
      <Text
        as="span"
        ml={2}
        mb="1px"
        fontFamily="quattrocento"
        fontWeight="bold"
        fontSize={[16, 18, 20]}
      >
        {suffix}
      </Text>
    </Text>
  )
}

export default ChapterHeading
