import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import AMThemeProvider from './AMThemeProvider'

const root = ReactDOM.createRoot(
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  document.getElementById('root')!
)
root.render(
  <React.StrictMode>
    <AMThemeProvider>
      <App />
    </AMThemeProvider>
  </React.StrictMode>
)
