import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  Link,
} from '@chakra-ui/react'

const Impressum = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button variant="link" onClick={onOpen}>
        Impressum
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent color="am.gray">
          <ModalHeader>Impressum</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={10}>
              Antje Mohr
              <br />
              Obere Liebfrauenstr. 38
              <br />
              61169 Friedberg
            </Text>

            <Text fontWeight="bold">Kontakt</Text>
            <Text mb={10}>E-Mail: mail@antje-mohr.com</Text>

            <Text fontWeight="bold">Bildnachweis</Text>
            <Text>
              <Link href="https://www.gettyimages.de/" target="_blank">
                https://www.gettyimages.de/
              </Link>
            </Text>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  )
}

export default Impressum
