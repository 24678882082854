import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { Box, Image } from '@chakra-ui/react'
import { BiSolidChevronRightSquare, BiSolidChevronLeftSquare } from 'react-icons/bi'

const Aquarell = () => {
  const carouselBtnStyles = {
    color: 'am.tuerkis.darker',
    opacity: 0.75,
    _before: { display: 'none' },
    _hover: { color: 'am.tuerkis.darker', opacity: 1 },
    fontSize: '4xl',
    w: 'auto',
    h: 'auto',
    zIndex: 1,
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    centerMode: true,
    className: 'center',
    nextArrow: (
      <Box {...carouselBtnStyles} right="-21px">
        <BiSolidChevronRightSquare />
      </Box>
    ),
    prevArrow: (
      <Box {...carouselBtnStyles} left="-21px">
        <BiSolidChevronLeftSquare />
      </Box>
    ),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <Box w="100%" px={8} height="400px" alignItems="center" m="auto">
      <Slider {...settings}>
        <Image boxSize="350px" objectFit="contain" src="/aquarell/cards_01.png" />
        <Image boxSize="350px" objectFit="contain" src="/aquarell/cards_02.png" />
        <Image boxSize="350px" objectFit="contain" src="/aquarell/cards_03.png" />
        <Image boxSize="350px" objectFit="contain" src="/aquarell/cards_05.png" />
        <Image boxSize="350px" objectFit="contain" src="/aquarell/cards_04.png" />
      </Slider>
    </Box>
  )
}

export default Aquarell
