import { ChakraProvider, extendTheme } from '@chakra-ui/react'

import '@fontsource-variable/oswald'
import '@fontsource/quattrocento'

import { type ReactNode } from 'react'

const theme = extendTheme({
  styles: {
    global: {
      html: {
        scrollBehavior: 'smooth',
      },
      '*': {
        justifyContent: 'center',
      },
      '.slick-slide > div': {
        margin: '0 16px',
      },
      '.slick-list': {
        margin: '0 -16px',
      },
      '.slick-dots': {
        bottom: '-50px',
      },
      '.slick-dots > li': {
        height: '20px',
        width: '20px',
      },
      '.slick-dots li button': {
        padding: 0,
      },
      '.slick-dots li button:before': {
        color: 'am.tuerkis.darker',
        fontSize: '10px',
      },
      '.slick-dots li.slick-active button:before': {
        color: 'am.tuerkis.darker',
        fontSize: '12px',
        opacity: 1,
      },
    },
  },
  fonts: {
    oswald: `'Oswald Variable', sans-serif;`,
    quattrocento: `'Quattrocento', serif;`,
    century: `century, serif;`,
  },
  colors: {
    am: {
      tuerkis: {
        lighter: '#aab8bb',
        light: '#c4d7d1',
        darker: '#638086',
        dark: '#5f9595',
      },
      orange: {
        dark: '#eb8873',
        light: '#f5d1c3',
      },
      gray: '#45484a',
    },
  },
  components: {
    Button: {
      variants: {
        link: {
          fontWeight: 'normal',
          color: 'inherit',
        },
      },
    },
  },
})

console.info(theme)

type AMThemeProviderProps = {
  children: ReactNode
}

const AMThemeProvider = ({ children }: AMThemeProviderProps) => {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>
}

export default AMThemeProvider
